.rcs-custom-scroll {
    min-height: 0;
    min-width: 0;
}
.rcs-custom-scroll .rcs-outer-container {
    overflow: hidden;
}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
    position: relative;
}
.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
    opacity: 1;
    transition-duration: 0.2s;
}
.rcs-custom-scroll .rcs-inner-container {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.rcs-custom-scroll .rcs-inner-container:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
    transition: height 0.1s ease-in;
    will-change: height;
}
.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
    height: 5px;
    transition: height 0.15s ease-out;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
    user-select: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar {
    position: absolute;
    top: 3rem;
    height: calc(100% - 6rem);
    width: 0.8rem;
    right: 1px;
    z-index: 1;
    border-radius: 0.4rem;
    transition: opacity 0.4s ease-out;
    padding: 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none;
    cursor: pointer;
    visibility: visible !important;
    opacity: 1 !important;
}
.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
    right: auto;
    left: 3px;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
    opacity: 1;
}
.rcs-custom-scroll .rcs-custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0;
    cursor: pointer;
}
.rcs-custom-scroll .rcs-inner-handle {
    height: calc(100% - 0.1rem);
    background-color: #002169;
    border-radius: 0.4rem;
    margin-top: 0 !important;
    cursor: pointer;
}