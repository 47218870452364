@font-face {
    font-family: 'Cervo';
    src: url('Cervo-Regular.woff2') format('woff2'),
    url('Cervo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cervo';
    src: url('Cervo-Medium.woff2') format('woff2'),
        url('Cervo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Light.woff2') format('woff2'),
    url('Stem-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-SemiLight.woff2') format('woff2'),
    url('Stem-SemiLight.woff') format('woff');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Bold.woff2') format('woff2'),
    url('Stem-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Druk Cyr';
    src: url('DrukCyr-Bold.woff2') format('woff2'),
    url('DrukCyr-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Druk Cyr';
    src: url('DrukCyr-Medium.woff2') format('woff2'),
    url('DrukCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}