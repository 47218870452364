$fontBase: 'Stem';
$fontDruk: 'Druk Cyr';
$fontCervo: 'Cervo';
$color: #000000;
$colorBl: #00143F;
$mb: 1023px;
$desk: 1024px;

*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: $fontBase;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 130%;
  color: $color;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
  }
}

body {
  overscroll-behavior: none;
  background: #00143F;
  height: auto;
}

html {
  font-size: 0.5208333vw;
}

.justify-wrap{
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus::-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.container{
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
  max-width: 100%;
  width: 100%;
  padding-right: 15.2rem;
  padding-left: 15.2rem;

  @media screen and (max-width: $mb){
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.wrapper{
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100vh;
}

.hidden {
  display: none !important;
}

.content:not(.content--start) {
  padding-top: 12.6rem;

  @media screen and (max-width: $mb){
    padding-top: 7.2rem;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 12.6rem;
  background-color: $colorBl;

  @media screen and (max-width: $mb){
    height: 7.2rem;
  }

  .container {
    padding-bottom: 2rem;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: $mb){
      padding-bottom: 0;
      align-items: center;
    }
  }

  &:before {
    content: '';
    background: linear-gradient(94deg, #FDDB87 1.37%, #FEDC87 24.89%, #EBA445 50.37%, #FED77F 76.83%, #F8C06C 95.45%);
    height: 0.3rem;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;

    @media screen and (max-width: $mb){
      height: 2px;
    }
  }
}

.logo {
  width: 13.3rem;
  display: block;
  cursor: pointer;

  @media screen and (max-width: $mb){
    width: 6.7rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.sc__promo {
  position: relative;
  height: 67.7rem;

  @media screen and (max-width: $mb){
    height: 50rem;

    &--loggedin {
      height: calc(100vh - 10rem);
    }
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
    justify-content: flex-end;

    @media screen and (max-width: $mb){
      justify-content: center;
      align-items: center;
      padding-top: 27.8rem;
      padding-bottom: 1.6rem;
      height: 100%;
    }
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: $mb){
      height: 100%;
    }

    picture, img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-ct {
    width: 50%;
    min-width: 50%;
    padding-left: 9.7rem;

    @media screen and (max-width: $mb){
      width: 100%;
      min-width: 100%;
      padding-left: 0;
    }
  }

  &-title {
    color: #FFF;
    font-family: $fontCervo;
    font-size: 9.631rem;
    font-style: normal;
    font-weight: 500;
    line-height: 95%;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 4.8rem;
      text-align: center;
    }
  }

  &-action {
    margin-top: 3.7rem;

    @media screen and (max-width: $mb){
      margin-top: 1.1rem;

      .btn {
        width: 100%;
      }
    }
  }
}

.zero__decor {
  position: absolute;
  width: 22.3rem;
  left: 50%;
  margin-left: 64.1rem;
  top: 35.8rem;
  z-index: 2;

  img {
    display: block;
    width: 100%;

  }
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.btn {
  height: 8.4rem;
  cursor: pointer;
  min-width: 26rem;
  color: #fff !important;
  background: #CE0E2D;
  font-size: 3.4rem;
  font-weight: 500;
  padding: 0 4rem;
  font-family: $fontDruk;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border: none;
  will-change: background-color, box-shadow;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  @media screen and (max-width: $mb){
    height: 5.4rem;
    font-size: 2.4rem;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &:not([disabled]):hover {
    background: rgba(#CE0E2D, 0.8);
    box-shadow: 0 0 0 0 rgba(#ffffff, 1);
  }

  &--shadow {
    box-shadow: -0.4rem 0.4rem 0 0 rgba(#ffffff, 1);

    @media screen and (max-width: $mb){
      box-shadow: -0.2rem 0.2rem 0 0 rgba(#ffffff, 1);
    }
  }

  &--white {
    background-color: #fff;
    color: #002169 !important;

    &:not([disabled]):hover {
      background: rgba(#fff, 0.8);
    }
  }

  &--border {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff !important;

    &:not([disabled]):hover {
      background: #fff;
      color: #002169 !important;
    }
  }

  &--small {
    height: 6.4rem;
    font-size: 3rem;

    @media screen and (max-width: $mb) {
      font-size: 2.4rem;
      height: 5.4rem;
    }
  }

  &--auto {
    width: auto;
    min-width: 0;
  }

  &--sticker {
    padding-right: 1.4rem;
    padding-left: 1.8rem;
    justify-content: space-between;
    min-width: 22.5rem;
    font-size: 3rem;

    @media screen and (max-width: $mb) {
      font-size: 2.2rem;
      min-width: 18rem;
    }

    &:not(:last-child){
      margin-right: 1rem;

      @media screen and (max-width: $mb) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.stickers__hint {
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 1rem;

  @media screen and (max-width: $mb) {
    position: relative;
    font-size: 1.2rem;
    bottom: 0;
    margin-top: 1.6rem;
  }
}

.btn__icon {
  width: 6rem;
  min-width: 6rem;
  height: 6rem;

  @media screen and (max-width: $mb) {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.promo {
  &__product {
    width: 17.2rem;
    position: absolute;
    top: 3.7rem;
    left: 50%;
    margin-left: -30.7rem;
    z-index: 2;

    @media screen and (max-width: $mb){
      width: 6.9rem;
      margin-left: -3.45rem;
      top: 50%;
      margin-top: -22rem;
    }

    &-img {
      position: relative;
      z-index: 2;

      img {
        display: block;
        width: 100%;
      }
    }

    &-logo {
      width: 58.6rem;
      position: absolute;
      top: 8.3rem;
      left: -51.1rem;
      transform: rotate(-26.241deg);

      @media screen and (max-width: $mb){
        width: 17.4rem;
        left: -13.8rem;
        top: 3rem;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__decor {
    &-1 {
      width: 19.9rem;
      position: absolute;
      left: -18.3rem;
      top: 34.5rem;

      @media screen and (max-width: $mb){
        width: 8rem;
        left: -7.4rem;
        top: 13.9rem;
      }
    }

    &-2 {
      width: 17.5rem;
      position: absolute;
      left: 13rem;
      top: -9rem;

      @media screen and (max-width: $mb){
        width: 7rem;
        left: 5.2rem;
        top: -3.6rem;
      }
    }

    &-3 {
      width: 27.6rem;
      position: absolute;
      left: 7.8rem;
      top: 14.8rem;

      @media screen and (max-width: $mb){
        width: 11.1rem;
        left: 3.1rem;
        top: 6rem;
      }
    }
  }
}

.sc__steps {
  @media screen and (min-width: $desk){
    background: url("../img/texture.jpg") repeat-y center top;
    background-size: 100%;
    margin-top: -1rem;
  }

  @media screen and (max-width: $mb){
    background: url("../img/texture-sm.jpg") repeat-y center top;
    background-size: 100%;
  }

  position: relative;
  z-index: 3;

  @media screen and (min-width: $desk){
    &:after {
      content: '';
      position: absolute;
      background: url("../img/sep-decor-1.png") no-repeat center;
      background-size: contain;
      width: 315.5rem;
      height: 8rem;
      left: 50%;
      top: -3rem;
      margin-left: -153.75rem;
      z-index: 3;
    }
  }

  .container {
    padding-top: 10rem;
    padding-bottom: 11rem;

    @media screen and (max-width: $mb){
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }

  .sc__header {
    margin-bottom: 6.2rem;

    @media screen and (max-width: $mb){
      margin-bottom: 4.7rem;
    }
  }
}

.sc__prizes {
  @media screen and (min-width: $desk){
    background: url("../img/texture.jpg") repeat-y center top;
    background-size: 100%;
  }

  @media screen and (max-width: $mb){
    background: url("../img/texture-sm.jpg") repeat-y center top;
    background-size: 100%;
  }

  position: relative;
  z-index: 3;

  .container {
    padding-top: 9rem;
    padding-bottom: 9rem;

    @media screen and (max-width: $mb){
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }

  .sc__header {
    margin-bottom: 5.4rem;

    @media screen and (max-width: $mb){
      margin-bottom: 4.9rem;
    }

    @media screen and (min-width: $desk){
      &:before {
        margin-left: -30.5rem;
        top: -9rem;
      }
    }
  }
}

.nav {
  &__box {
    height: 100%;
    margin-right: 8rem;
    margin-left: auto;
  }

  &__bl {
    height: 100%;
  }

  &__menu {
    @media screen and (min-width: $desk){
      height: 100%;
    }

    ul {
      height: 100%;
      display: flex;
      padding: 0;
      margin: 0;

      @media screen and (max-width: $mb){
        flex-wrap: wrap;
      }

      li {
        @media screen and (max-width: $mb){
          width: 100%;
          text-align: center;
        }

        &:not(:last-child){
          margin-right: 4rem;

          @media screen and (max-width: $mb){
            margin-right: 0;
            margin-bottom: 3.2rem;
          }
        }

        display: flex;
        align-items: flex-end;
        text-transform: uppercase;
        font-family: $fontDruk;
        font-weight: 500;
        font-size: 3rem;
        position: relative;

        @media screen and (max-width: $mb){
          font-size: 2.4rem;
          text-align: center;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        @media screen and (min-width: $desk){
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 0.5rem;
            background: rgba(255, 255, 255, 0.10);
          }
        }

        a {
          color: #fff !important;
          will-change: color;
          transition: color 0.2s ease;

          &:hover {
            color: #CE0E2D !important;
          }
        }
      }
    }
  }
}

.sc__title {
  color: #002169;
  font-size: 9rem;
  font-family: $fontDruk;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  z-index: 2;

  @media screen and (max-width: $mb){
    font-size: 4rem;
  }
}

.sc__header {
  position: relative;

  &:before {
    content: '';
    background: url("../img/title-decor.svg") no-repeat center;
    background-size: contain;
    width: 46rem;
    height: 28.6rem;
    left: 50%;
    position: absolute;
    margin-left: -37.8rem;
    transform: rotate(-23.087deg);
    top: -8.5rem;

    @media screen and (max-width: $mb){
      width: 19.7rem;
      height: 12.2rem;
      margin-left: -11.5rem;
      top: -3.5rem;
    }
  }
}

.sc__steps {
  position: relative;
}

.steps {
  @media screen and (max-width: $mb){
    &__wrap {
      flex-wrap: wrap;
    }
  }

  &__left {
    min-width: 50.8rem;
    width: 50.8rem;
    margin-right: 8rem;

    @media screen and (max-width: $mb){
      min-width: 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 4.8rem;
    }
  }

  &__right {
    width: calc(100% - 58.8rem);

    @media screen and (max-width: $mb){
      width: 100%;
    }
  }

  &__it {
    &:not(:last-child){
      margin-bottom: 3.2rem;
    }

    &-numb {
      width: 10.8rem;
      font-family: $fontDruk;
      min-width: 10.8rem;
      font-weight: 700;
      font-size: 9rem;
      line-height: 7.2rem;
      text-transform: uppercase;
      color: #002169;

      @media screen and (max-width: $mb){
        font-size: 5rem;
        line-height: 5rem;
        width: 3.7rem;
        min-width: 3.7rem;
        margin-right: 2rem;
      }
    }

    &-title {
      margin-bottom: 2rem;
      color: #002169;
      font-family: $fontDruk;
      font-size: 5rem;
      font-weight: 500;
      line-height: 4.2rem;
      text-transform: uppercase;

      @media screen and (max-width: $mb){
        font-size: 3rem;
        line-height: 130%;
        margin-bottom: 1.6rem;
      }
    }

    @media screen and (min-width: $desk){
      &-descr {
        min-height: 6.2rem;
      }
    }
  }

  &__sl {
    &-row {
      margin-right: -1.6rem;
      margin-left: -1.6rem;

      @media screen and (max-width: $mb){
        margin-right: -1.2rem;
        margin-left: -1.2rem;
      }
    }

    @media screen and (max-width: $mb){
      &-box {
        position: relative;
        padding-left: 4.4rem;
        padding-right: 4.4rem;

        .keen-slider {
          overflow: visible !important;
        }

        .sl__nav {
          position: absolute;
          top: 50%;
          z-index: 5;
          margin-top: -2.2rem;

          &--prev {
            left: -0.4rem;
          }

          &--next {
            right: -0.4rem;
            left: auto;
            margin-left: 0;
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  &__slide {
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    @media screen and (max-width: $mb){
      padding-right: 1.2rem;
      padding-left: 1.2rem;
    }
  }

  &__decor-1 {
    position: absolute;
    width: 34.4rem;
    right: 0;
    top: 39rem;
  }

  &__decor-2 {
    position: absolute;
    width: 32.7rem;
    left: 3.3rem;
    top: 6rem;
  }

  &__decor-3 {
    position: absolute;
    width: 41.7rem;
    right: -5.6rem;
    top: 11rem;
  }
}

.card {
  border-radius: 3rem;
  background: #FFF;
  padding: 2.5rem 2.5rem 2rem;

  @media screen and (max-width: $mb){
    border-radius: 2rem;
  }

  &__img {
    margin-bottom: 1.1rem;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 1.3rem;
    color: #CE0E2D;
    font-family: $fontDruk;
    font-size: 3rem;
    font-weight: 500;
    line-height: normal;

    @media screen and (max-width: $mb){
      font-size: 2.4rem;
      margin-bottom: 1.2rem;
    }
  }

  &__title {
    color: #002169;
    text-align: center;
    font-family: $fontDruk;
    font-size: 4rem;
    font-weight: 500;
    line-height: 95%;
    text-transform: uppercase;
    margin-bottom: 1.4rem;

    &--small {
      font-size: 3.5rem;
      min-height: 7.6rem;
    }

    @media screen and (max-width: $mb){
      font-size: 3rem;
    }
  }

  &__hint {
    min-height: 1.8rem;
    color: #000;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 300;

    @media screen and (max-width: $mb){
      font-size: 1rem;
      line-height: 130%;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }
}

.sc__stickers-2 {
  background: #fff;
  position: relative;
  height: 13.2rem;

  &:after {
    content: '';
    position: absolute;
    background: url("../img/sep-decor-4.svg") no-repeat center bottom;
    background-size: cover;
    height: 2rem;
    width: 192rem;
    left: 50%;
    top: -1.8rem;
    margin-left: -96rem;
    z-index: 5;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mb){
      padding-bottom: 4rem;
      padding-top: 1.6rem;
    }
  }
}

.sc__stickers {
  position: relative;
  background: #002169;

  .container {
    padding-top: 1.2rem;
    padding-bottom: 3.6rem;

    @media screen and (max-width: $mb){
      padding-bottom: 1.5rem;
      padding-top: 1rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    background: url("../img/sep-decor-2.svg") no-repeat center top;
    background-size: cover;
    height: 3.3rem;
    width: 192rem;
    left: 50%;
    top: -2.6rem;
    margin-left: -96rem;
    z-index: 5;
  }

  &:after {
    content: '';
    position: absolute;
    background: url("../img/sep-decor-3.svg") no-repeat center bottom;
    background-size: cover;
    height: 2.5rem;
    width: 192rem;
    left: 50%;
    bottom: -2.5rem;
    margin-left: -96rem;
    z-index: 5;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
    }
  }
}

.stickers {
  &__ct {
    display: flex;
    align-items: center;
    margin-right: 3.2rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
    }
  }

  &__img {
    margin-right: 1.6rem;
    width: 13.2rem;

    @media screen and (max-width: $mb){
      width: 9rem;
      min-width: 9rem;
      margin-right: 1.4rem;
    }
  }

  &__title {
    color: #FFF;
    text-align: center;
    font-family: $fontCervo;
    font-size: 5rem;
    font-weight: 500;
    line-height: 95%;
    text-transform: uppercase;
    margin-top: 0.75rem;

    @media screen and (max-width: $mb){
      font-size: 3.2rem;
      min-width: 23rem;
      text-align: left;
    }
  }

  @media screen and (max-width: $mb){
    &__sep {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.prizes {
  &__sl {
    &-row {
      margin-left: -1.6rem;
      margin-right: -1.6rem;

      @media screen and (max-width: $mb){
        margin-left: -1.2rem;
        margin-right: -1.2rem;
      }
    }

    .keen-slider {
      overflow: visible !important;
    }

    &-box {
      margin-right: -23.5rem;
      margin-left: -23.5rem;
      margin-bottom: 5.6rem;
      position: relative;
      //margin-left: auto;
      //margin-right: auto;
      //max-width: 137.6rem;

      @media screen and (max-width: $mb){
        margin-right: 0;
        margin-left: 0;
        padding-left: 4.4rem;
        padding-right: 4.4rem;
        margin-bottom: 4.8rem;

        .sl__nav {
          position: absolute;
          top: 50%;
          z-index: 5;
          margin-top: -2.2rem;

          &--prev {
            left: -0.4rem;
          }

          &--next {
            right: -0.4rem;
            left: auto;
            margin-left: 0;
            margin-right: 0 !important;
          }
        }
      }
    }

    &-action {
      justify-content: center;

      .btn {
        min-width: 67.2rem;

        @media screen and (max-width: $mb){
          min-width: 100%;
        }
      }
    }

    &-hint {
      text-align: center;
      color: #000;
      font-size: 1.4rem;
      font-weight: 300;
      margin-top: 3.2rem;
      line-height: 130%;

      @media screen and (max-width: $mb){
        margin-top: 2.4rem;
        font-size: 1rem;
      }
    }
  }
}

.sc__prizes {
  .card__title {
    min-height: 7.6rem;

    @media screen and (max-width: $mb) {
      min-height: 5.6rem;
      margin-bottom: 0;
    }
  }
}

.sc__events {
  position: relative;
  background: url("../img/texture-2.jpg") no-repeat center;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    z-index: 3;

    @media screen and (min-width: $desk){
      background: url("../img/sep-decor-1.png") no-repeat center;
      background-size: contain;
      width: 315.5rem;
      height: 8rem;
      left: 50%;
      top: -3.6rem;
      margin-left: -153.75rem;
    }

    @media screen and (max-width: $mb){
      background: url("../img/sep-decor-sm.png") no-repeat center;
      background-size: contain;
      width: 100%;
      height: 4.3rem;
      left: 0;
      top: -2.15rem;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background: url("../img/texture-3.png") repeat-x;
    height: 4.8rem;
    left: 0;
    right: 0;
    bottom: -2.4rem;
    background-size: contain;
    z-index: 3;
  }

  .container {
    padding-top: 12rem;
    padding-bottom: 15rem;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $mb) {
      padding-top: 7rem;
      padding-bottom: 8rem;
    }
  }

  .sc__header {
    margin-bottom: 5rem;

    @media screen and (max-width: $mb) {
      margin-bottom: 4.8rem;
    }

    &:before {
      display: none;
    }
  }

  .sc__title {
    color: #fff !important;
    text-align: left;

    @media screen and (max-width: $mb) {
      text-align: center;
    }
  }
}

.events {
  &__wrap {
    justify-content: space-between;

    @media screen and (max-width: $mb) {
      flex-wrap: wrap;
    }
  }

  &__left {
    width: 50.2rem;
    min-width: 50.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: $mb) {
      width: 100%;
      min-width: 100%;
      margin-bottom: 3.2rem;
    }

    &-hd, &-bt {
      width: 100%;
    }

    &-bt {
      padding-top: 2rem;
      padding-bottom: 0.2rem;
      position: relative;

      .btn {
        margin-right: 2.2rem;

        @media screen and (max-width: $mb) {
          margin-right: 0;
          width: 100%;
        }
      }

      &-title {
        color: #FFF;
        font-family: $fontDruk;
        font-size: 3rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 1rem;
        margin-top: -1rem;

        @media screen and (max-width: $mb) {
          font-size: 2.4rem;
          margin-top: 0;
        }
      }
    }

    &-title {
      margin-bottom: 2rem;
      color: #FFF;
      font-family: $fontDruk;
      font-size: 5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;

      @media screen and (max-width: $mb) {
        font-size: 3rem;
        margin-bottom: 1.6rem;
      }
    }

    &-small {
      color: #FFF;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 130%;

      @media screen and (max-width: $mb) {
        font-size: 1.4rem;
        margin-bottom: 1.9rem;
      }
    }

    &-descr {
      max-width: 40rem;
      color: #fff;
    }
  }

  &__right {
    width: 102.4rem;
    min-width: 102.4rem;
    position: relative;

    @media screen and (max-width: $mb) {
      width: 100%;
      min-width: 100%;
      margin-bottom: 5.4rem;
    }
  }

  &__sl {
    &-box {
      margin-right: -35.2rem;

      @media screen and (max-width: $mb) {
        margin-right: 0;
        padding-right: 8.8rem;

        .keen-slider {
          overflow: visible !important;
        }
      }
    }

    &-row {
      margin-right: -1.6rem;
      margin-left: -1.6rem;

      @media screen and (max-width: $mb) {
        margin-right: -1.2rem;
        margin-left: -1.2rem;
      }
    }
  }

  &__slide {
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    @media screen and (max-width: $mb) {
      padding-right: 1.2rem;
      padding-left: 1.2rem;
    }
  }

  &__card {
    &-img {
      margin-bottom: 2.4rem;
      border-radius: 3rem;
      overflow: hidden;

      @media screen and (max-width: $mb) {
        border-radius: 2rem;
      }
    }

    &-title {
      margin-bottom: 1.6rem;
      color: #FFF;
      font-family: $fontDruk;
      font-size: 3rem;
      font-weight: 500;
      line-height: 95%;

      @media screen and (max-width: $mb) {
        font-size: 2.4rem;
      }
    }

    &-type {
      margin-bottom: 0.8rem;
      color: #FFF;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.12rem;
      text-transform: uppercase;
    }

    &-descr {
      font-size: 1.8rem;
      color: #fff;
      line-height: 2.3rem;

      @media screen and (max-width: $mb) {
        font-size: 1.3rem;
        line-height: 130%;
      }
    }
  }

  &__decor-1 {
    width: 32.7rem;
    position: absolute;
    margin-left: -8.3rem;
    top: 4.6rem;
    left: 50%;

    @media screen and (max-width: $mb){
      width: 16.7rem;
      right: 2rem;
      left: auto;
      margin-left: 0;
      bottom: 21.5rem;
      top: auto;
    }
  }

  &__decor-2 {
    width: 9.8rem;
    position: absolute;
    margin-left: -88.9rem;
    top: 44.8rem;
    left: 50%;
  }
}

.sl__nav {
  width: 4.4rem;
  height: 4.4rem;
  cursor: pointer;
  will-change: opacity;
  transition: opacity 0.2s ease;
  z-index: 5;

  @media screen and (min-width: $desk) {
    opacity: 0.6;
  }

  &:not(:last-child){
    margin-right: 1rem;
  }

  &-box {
    display: flex;
    right: 0;
    position: absolute;
    bottom: 100%;
    margin-bottom: 7.7rem;
    z-index: 5;

    @media screen and (max-width: $mb){
      position: relative;
      bottom: auto;
      margin-top: 1.2rem;
      margin-bottom: 0;
    }
  }

  &:hover {
    opacity: 1;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.sc__faq {
  position: relative;
  background: url("../img/texture-2.jpg") no-repeat center;
  background-size: cover;

  @media screen and (max-width: $mb){
    background-size: 100%;
    background-repeat: repeat-y;
  }

  .container {
    position: relative;
    z-index: 3;
    padding-top: 9rem;
    padding-bottom: 6.5rem;

    @media screen and (max-width: $mb){
      padding-top: 6.4rem;
    }
  }

  .sc__header {
    &:before {
      display: none;
    }

    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      margin-bottom: 3.2rem;
    }
  }

  .sc__title {
    color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 3;

    @media screen and (min-width: $desk) {
      background: url("../img/sep-decor-1.png") no-repeat center;
      background-size: contain;
      width: 315.5rem;
      height: 8rem;
      left: 50%;
      top: -4rem;
      margin-left: -153.75rem;
    }

    @media screen and (max-width: $mb){
      background: url("../img/sep-decor-sm.png") no-repeat center;
      background-size: contain;
      width: 100%;
      height: 4.3rem;
      left: 0;
      top: -2.15rem;
    }
  }
}

.anchor {
  position: absolute;
  height: 9rem;
  top: -9rem;
  min-height: 1px;
  width: 100%;

  @media screen and (max-width: $mb){
    height: 7rem;
    top: auto;
    bottom: 100%;
    margin-bottom: -4rem;
  }
}

.sc__promo {
  .anchor {
    height: 12.6rem;
    top: -12.6rem;

    @media screen and (max-width: $mb){
      height: 7rem;
      top: -8rem;
    }
  }
}

.sc__winners {
  @media screen and (min-width: $desk){
    background: url("../img/texture.jpg") repeat-y center top;
    background-size: 100%;

    &:before {
      content: '';
      position: absolute;
      background: url("../img/winners-bg.png") no-repeat right top;
      background-size: cover;
      top: 2.5rem;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  @media screen and (max-width: $mb){
    background: url("../img/texture-sm.jpg") repeat-y center top;
    background-size: 100%;
  }

  position: relative;

  .container {
    padding-top: 8rem;
    padding-bottom: 8rem;
    position: relative;
    z-index: 4;

    @media screen and (max-width: $mb){
      padding-top: 7rem;
      padding-bottom: 7rem;
    }
  }

  .sc__header {
    margin-bottom: 4.4rem;

    @media screen and (max-width: $mb){
      margin-bottom: 3.8rem;
    }
  }
}

.winners {
  &__wrap {
    position: relative;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
    }
  }

  &__filter {
    width: 81rem;
    position: relative;

    @media screen and (max-width: $mb){
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .sl__nav {
      position: absolute;
      top: 50%;
      margin-top: -2.2rem;

      @media screen and (max-width: $mb){
        width: 3.6rem;
        height: 3.6rem;
        margin-top: -1.8rem;
      }

      &--prev {
        right: 100%;
        margin-right: 0.65rem;

        @media screen and (max-width: $mb){
          margin-right: -1.7rem;
        }
      }

      &--next {
        left: 100%;
        margin-left: 0.65rem;

        @media screen and (max-width: $mb){
          margin-left: -1.7rem;
        }
      }
    }

    &-sl {
      margin-left: -0.5rem;
      margin-right: -0.5rem;

      @media screen and (max-width: $mb){
        margin-left: -0.25rem;
        margin-right: -0.25rem;
      }
    }

    @media screen and (max-width: $mb){
      /*margin-right: -2rem;
      margin-left: -2rem;
      overflow-x: auto;
      overflow-y: hidden;*/
      margin-bottom: 1.6rem;
    }

    .keen-slider__slide {
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      @media screen and (max-width: $mb){
        padding-right: 0.25rem;
        padding-left: 0.25rem;
      }
    }

    &-box {
      position: relative;
      z-index: 4;
      margin-bottom: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: $mb){
        flex-wrap: wrap;
        margin-bottom: 2.4rem;
      }
    }

    &-scroll {
      display: flex;

      @media screen and (max-width: $mb){
        padding-left: 2rem;
      }
    }

    &-it {
      background: #fff;
      will-change: background-color, border-color;
      transition: background-color 0.2s ease, border-color 0.2s ease;
      height: 5rem;
      border-radius: 2.5rem;
      padding: 0 2.2rem;
      color: #002169;
      cursor: pointer;
      font-family: $fontDruk;
      font-size: 2.4rem;
      font-weight: 500;
      text-align: center;
      line-height: 5rem;
      white-space: nowrap;
      text-transform: uppercase;

      &.future {
        cursor: default;
        opacity: 0.4;
      }

      @media screen and (max-width: $mb){
        //width: 11.2rem;
        padding: 0;
        text-align: center;
        //min-width: 11.2rem;
        font-size: 2rem;
        line-height: 4.8rem;
        height: 4.8rem;
        border-radius: 2.4rem;
      }

      &:not(.future):hover {
        background-color: rgba(#002169, 0.25);
      }

      &.active {
        background: #002169;
        color: #fff !important;
      }

      &:not(:last-child){
        margin-right: 0.8rem;
      }
    }
  }
}

.form {
  &__field {
    input, textarea {
      height: 6rem;
      border-radius: 3rem;
      background: #fff;
      border: 2px solid #fff;
      padding: 0 2.4rem;
      color: #002169;
      font-family: $fontDruk;
      font-size: 2.4rem;
      width: 100%;
      font-weight: 500;
      text-transform: uppercase;

      @media screen and (max-width: $mb) {
        height: 5rem;
        border-radius: 2.5rem;
        padding: 0 2rem;
        font-size: 2rem;
      }

      &[disabled] {
        color: rgba(#000, 0.5);
        cursor: not-allowed;
      }

      &:focus {
        border-color: rgba(#002169, 0.5);
      }

      &::-webkit-input-placeholder {
        color: rgba(#002169, 0.5);
      }

      &:-moz-placeholder {
        color: rgba(#002169, 0.5);
      }

      &::-moz-placeholder {
        color: rgba(#002169, 0.5);
      }

      &:-ms-input-placeholder {
        color: rgba(#002169, 0.5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-text-fill-color: #002169;
        -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
      }
    }

    textarea {
      resize: none;
      height: 12rem;
      padding-top: 1.4rem;

      @media screen and (max-width: $mb) {
        padding-top: 1.2rem;
      }
    }

    &.has-error {
      input, textarea {
        border-color: #CE0E2D;
      }
    }
  }

  &__label {
    color: #002169;
    font-family: $fontDruk;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.8rem;
    padding: 0 2.4rem;
    text-transform: uppercase;
    margin-bottom: 0.8rem;

    @media screen and (max-width: $mb) {
      font-size: 1.4rem;
    }
  }

  &__it {
    &:not(:last-child){
      margin-bottom: 2rem;

      @media screen and (max-width: $mb) {
        margin-bottom: 1.6rem;
      }
    }
  }
}


.checkbox__wrap {
  display: flex;

  label {
    display: flex;
    font-weight: normal;
    margin: 0;
    position: relative;
    align-items: center;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked {
        & ~ .checkbox__decor {
          background-color: #002169;
          border-color: #002169;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &.has-error {
    .checkbox__decor {
      border-color: #E31D36 !important;
    }
  }
}

.checkbox__decor {
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  border: 1px solid #ffffff;
  margin-right: 1.2rem;
  position: relative;
  background-color: #ffffff;
  will-change: background-color, border-color;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border-radius: 0.8rem;

  @media screen and (max-width: $mb){
    margin-right: 1rem;
    width: 2.8rem;
    min-width: 2.8rem;
    height: 2.8rem;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../img/checked.svg") no-repeat center;
    background-size: contain;
    left: 0;
    top: 0;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s ease;
  }
}

.checkbox__it {
  &:not(:last-child){
    margin-bottom: 1.6rem;
  }
}

.checkbox__title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 350;
  line-height: 130%;
  user-select: none;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
  }

  a {
    color: #000000 !important;
    border-bottom: 1px solid #000000;
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.win__search {
  position: relative;

  &-bl {
    width: 31.2rem;
  }

  .form__field {
    input {
      height: 5rem;

      @media screen and (max-width: $mb){
        height: 4.8rem;
        border-radius: 2.4rem;
        font-size: 2rem;
      }
    }
  }
}

.search__submit {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: #002169;
  position: absolute;
  right: 0;
  cursor: pointer;
  border: none;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: background-color;
  transition: background-color 0.2s ease;

  @media screen and (max-width: $mb){
    width: 4.8rem;
    height: 4.8rem;
  }

  &:hover {
    background-color: #CE0E2D;
  }

  svg {
    display: block;
    width: 2rem;
    height: 2rem;

    @media screen and (max-width: $mb){
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.tb {
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__col {
    &:nth-child(1){
      width: 30%;
      min-width: 30%;
      padding-left: 5.8rem;
    }

    &:nth-child(2){
      width: 30%;
      min-width: 30%;
    }

    &:nth-child(3){
      width: 40%;
      min-width: 40%;
      padding-right: 4rem;
    }
  }

  &__img {
    width: 5rem;
    min-width: 5rem;
    border-radius: 1.2rem;
    overflow: hidden;
    border: 1px solid #D6D8DD;
    margin-right: 1.5rem;
  }

  &__th {
    color: #002169;
    font-family: $fontDruk;
    font-size: 3rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.1rem;
  }

  &__td {
    color: #002169;
    font-size: 1.8rem;

    &--prize {
      align-items: center;
    }
  }

  &__body {
    .tb__row {
      border-radius: 2rem;
      background: #FFF;
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:not(:last-child){
        margin-bottom: 1.6rem;
      }
    }
  }
}

.box__sl-nav {
  width: 4.4rem;
  height: 4.4rem;
  min-width: 4.4rem;
  cursor: pointer;
  will-change: opacity;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    cursor: default;
    opacity: 0.2;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  &--prev {
    margin-right: 2rem;
  }

  &--next {
    margin-left: 2rem;
  }
}

.pager {
  margin-top: 2.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;

  &__list {
    align-items: center;
    justify-content: center;
  }

  &__item {
    color: #002169;
    font-family: $fontDruk;
    font-size: 3rem;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    will-change: color;
    transition: color 0.2s ease;

    @media screen and (max-width: $mb){
      font-size: 2.4rem;
    }

    &:not(.pager__item-dots):hover {
      color: rgba(#002169, 0.75);
    }

    &.active {
      color: #CE0E2D;
    }

    &.pager__item-dots {
      cursor: default;
    }

    &:before {
      content: '';
      position: absolute;
      left: -0.8rem;
      right: -0.8rem;
      top: 0;
      bottom: 0;
    }

    &:not(:last-child){
      margin-right: 2rem;
    }
  }
}

.winners {
  &__decor-1 {
    width: 20.7rem;
    position: absolute;
    margin-left: 62.1rem;
    left: 50%;
    top: 9.5rem;
  }

  &__decor-2 {
    width: 40rem;
    position: absolute;
    margin-left: -86.3rem;
    left: 50%;
    bottom: -14rem;
  }
}

.faq {
  &__wrap {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
    }
  }

  &__it {
    padding: 2.4rem 3.2rem 2.6rem 4.8rem;
    cursor: pointer;

    @media screen and (max-width: $mb){
      padding: 2.4rem 1.6rem 2.4rem 2.4rem;
    }

    &:not(:last-child){
      border-bottom: 1px solid #D6D8DD;
    }

    &-hd {
      justify-content: space-between;
      align-items: flex-start;
    }

    &-title {
      color: #002169;
      font-family: $fontDruk;
      font-size: 3rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media screen and (max-width: $mb){
        font-size: 2rem;
        line-height: 110%;
      }
    }

    &-txt {
      color: #002169;
      font-size: 1.8rem;
      max-width: 70rem;
      margin-top: 1.4rem;

      a {
        color: #002169;
        border-bottom: 1px solid rgba(#002169, 0.6);
        will-change: border-color;
        transition: border-color 0.2s ease;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }

      @media screen and (max-width: $mb){
        font-size: 1.4rem;
        margin-top: 1.6rem;
      }
    }

    &-icon {
      width: 2rem;
      min-width: 2rem;
      margin-top: 1rem;
      height: 2rem;
      will-change: transform;
      transition: transform 0.2s ease;

      @media screen and (max-width: $mb){
        width: 1.6rem;
        min-width: 1.6rem;
        margin-top: 0.4rem;
        height: 1.6rem;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &.active {
      .faq__it-icon {
        transform: rotate(45deg);
      }
    }

    &:not(.active){
      .faq__it-answer {
        display: none !important;
      }
    }
  }

  @media screen and (min-width: $desk){
    &__scroll {
      height: 51.5rem;
    }
  }

  &__left {
    width: 82rem;
    min-width: 82rem;
    border-radius: 3rem;
    background: #FFFFFF;
    overflow: hidden;

    @media screen and (max-width: $mb){
      width: 100%;
      min-width: 100%;
      border-radius: 1.6rem;
    }
  }

  &__right {
    width: 26rem;
    min-width: 26rem;
    text-align: center;
    color: #fff;
    padding-top: 7rem;

    @media screen and (max-width: $mb){
      width: 100%;
      min-width: 100%;
      padding-top: 0;
      margin-top: 6rem;

      .btn {
        width: 100%;
      }
    }
  }
}

.feedback__title {
  margin-bottom: 2rem;
  font-family: $fontDruk;
  font-size: 5rem;
  font-weight: 500;
  line-height: 95%;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    font-size: 3rem;
    line-height: 110%;
    margin-bottom: 1.6rem;
  }
}

.feedback__descr {
  margin-bottom: 3.8rem;

  @media screen and (max-width: $mb){
    margin-bottom: 3.2rem;
  }
}

.faq__decor-1 {
  position: absolute;
  width: 112.3rem;
  left: 50%;
  top: 25.5rem;
  margin-left: 15.1rem;
}

.faq__decor-2 {
  position: absolute;
  width: 112.3rem;
  left: 50%;
  top: 25.5rem;
  margin-left: -186.3rem;
}

.faq__decor-3 {
  position: absolute;
  width: 32.7rem;
  left: 50%;
  top: 6.5rem;
  margin-left: 52.4rem;
}

.stickers__imgs {
  display: flex;
  margin-right: 3.8rem;

  @media screen and (max-width: $mb) {
    margin-right: 0;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  .stickers__img {
    width: 12rem;
    margin-right: 0;

    @media screen and (max-width: $mb) {
      width: 10rem;
    }

    &:not(:last-child){
      margin-right: 0.8rem;
    }
  }
}

.stickers__title-2 {
  color: #002169;
  font-family: $fontCervo;
  font-size: 8.6rem;
  font-weight: 500;
  line-height: 95%;
  white-space: nowrap;
  text-transform: uppercase;
  padding-top: 0.4rem;

  @media screen and (max-width: $mb){
    font-size: 3.2rem;
    line-height: 95%;
    padding-top: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 1.2rem;
  }
}

.footer{
  background: #00143F;
  padding-bottom: 4rem;

  .container {
    padding-top: 7.4rem;

    @media screen and (max-width: $mb){
      padding-top: 6.8rem;
    }
  }

  &__rights {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2rem;

    @media screen and (max-width: $mb){
      text-align: center;
      margin-bottom: 2.2rem;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 117rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4.2rem;
    border-bottom: 1px solid rgba(#fff, 0.2);

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
      max-width: 100%;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25rem;
    min-width: 25rem;

    @media screen and (max-width: $mb){
      width: 100%;
      min-width: 100%;
    }
  }

  &__right {
    width: 75.3rem;
    min-width: 75.3rem;

    @media screen and (max-width: $mb){
      width: 100%;
      min-width: 100%;
    }
  }

  &__logo {
    width: 15.4rem;

    @media screen and (max-width: $mb){
      width: 10.2rem;
      margin: 0 auto 4rem;
    }
  }

  &__legal {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 350;
    line-height: 2rem;
    margin-right: -22.3rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
      text-align: center;
      margin-bottom: 5rem;
    }
  }

  &__nav {
    margin-bottom: 5.6rem;
    margin-right: -22.3rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 4rem;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;

      @media screen and (max-width: $mb){
        flex-wrap: wrap;
        justify-content: center;
      }

      li {
        color: #FFF;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: 0.28rem;
        text-transform: uppercase;

        @media screen and (max-width: $mb){
          width: 100%;
          text-align: center;
          font-size: 1.2rem;
          letter-spacing: 0.24rem;
        }

        a {
          color: #fff;
          will-change: color;
          transition: color 0.2s ease;
          cursor: pointer;

          &:hover {
            color: rgba(#fff, 0.7);
          }
        }

        &:not(:last-child){
          margin-right: 10rem;

          @media screen and (max-width: $mb){
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

.form__it-justify {
  justify-content: space-between;
}

.form__it-center {
  align-items: center;
}

.error__message {
  color: #CE0E2D;
  font-family: $fontDruk;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0 2.4rem;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 2rem;
  margin-top: 0.4rem;

  @media screen and (max-width: $mb) {
    padding: 0 2rem;
    font-size: 1.6rem;
  }
}

.tb__empty {
  background: #fff;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  min-height: 30rem;
  position: relative;

  @media screen and (max-width: $mb) {
    border-radius: 1.6rem;
    font-size: 1.6rem;
    min-height: 24rem;
  }

  &.tb--loading {
    color: rgba($color, 0.3);
  }
}

.form--loading, .tb--loading {
  position: relative;

  .form__it, .tb__row, .modal__ct-box > *, .quiz__grid, .quiz__title, & > .form__field, & > button, & > .btn, .events__left-bt-ct {
    opacity: 0.2;
  }

  &:before{
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    position: absolute;
  }

  &:after {
    content: '';
    background: url("../img/loader.svg") no-repeat center;
    background-size: contain;
    width: 12.6rem;
    height: 12.6rem;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -6.3rem;
    margin-top: -6.3rem;

    @media screen and (max-width: $mb){
      width: 9.6rem;
      height: 9.6rem;
      margin-left: -4.8rem;
      margin-top: -4.8rem;
    }
  }
}

.win__search {
  &:after {
    width: 9.6rem;
    height: 9.6rem;
    margin-left: -4.8rem;
    margin-top: -4.8rem;
  }
}

.events__left-bt {
  &:after {
    background-image: url("../img/loader-wh.svg");
  }
}

body.modal--open {
  overflow: hidden;
}

.form__select-group {
  display: flex;
  gap: 0.8rem;

  .form__select {
    width: 13rem;

    @media screen and (max-width: $mb) {
      width: 8.2rem;
    }

    &--month {
      width: 17.4rem;

      @media screen and (max-width: $mb) {
        width: 10.5rem;
      }
    }
  }
}

.form__select {
  position: relative;

  &-input {
    border-radius: 3rem;
    background: #FFF;
    height: 6rem;
    align-items: center;
    padding-left: 2.3rem;
    padding-right: 1.8rem;
    justify-content: space-between;
    border: 2px solid #fff;
    cursor: pointer;

    @media screen and (max-width: $mb){
      height: 5rem;
      padding-left: 2rem;
      border-radius: 2.5rem;
      padding-right: 0.8rem;
    }
  }

  &-toggle {
    width: 2rem;
    height: 2rem;
    will-change: transform;
    transition: transform 0.2s ease;

    @media screen and (max-width: $mb){
      width: 1.6rem;
      height: 1.6rem;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-value {
    color: #002169;
    font-family: $fontDruk;
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 2rem;
    }

    &--placeholder {
      opacity: 0.5;
    }
  }

  &-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 3rem 0.4rem 0.4rem 3rem;
    z-index: 5;
    box-shadow: 0 1px 3px rgba(#000, 0.3);
    max-height: 20rem;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;
    transition: opacity 0.1s ease, visibility 0.1s ease;

    @media screen and (max-width: $mb){
      border-radius: 2.5rem 0.4rem 0.4rem 2.5rem;
    }

    &::-webkit-scrollbar {
      width: 0.8rem;
      background-color: rgba(#002169, 0.2);
      border-radius: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #002169;
      border-radius: 0.4rem;
      cursor: pointer;
    }

    &-it {
      color: #002169;
      background-color: transparent;
      font-family: $fontDruk;
      font-size: 2.4rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0.4rem 2.4rem;
      cursor: pointer;
      will-change: background-color;
      transition: background-color 0.2s ease;

      @media screen and (max-width: $mb){
        font-size: 2rem;
        padding: 0.4rem 2rem;
      }

      &:hover {
        background-color: rgba(#002169, 0.1);
      }

      &.active {
        background-color: rgba(#002169, 1);
        color: #fff;
      }
    }
  }

  &.active {
    .form__select {
      &-input {
        border-color: rgba(#002169, 0.5);
      }

      &-dropdown {
        opacity: 1;
        visibility: visible;
      }

      &-toggle {
        transform: rotateX(180deg);
      }
    }
  }

  &.has-error {
    .form__select {
      &-input {
        border-color: #CE0E2D;
      }
    }
  }
}

.sugg__box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 3rem 0.4rem 0.4rem 3rem;
  z-index: 5;
  box-shadow: 0 1px 3px rgba(#000, 0.3);
  max-height: 20rem;
  overflow-y: auto;

  @media screen and (max-width: $mb){
    border-radius: 2.5rem 0.4rem 0.4rem 2.5rem;
  }

  &::-webkit-scrollbar {
    width: 0.8rem;
    background-color: rgba(#002169, 0.2);
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #002169;
    border-radius: 0.4rem;
    cursor: pointer;
  }
}

.sugg__item {
  color: #002169;
  background-color: transparent;
  font-family: $fontDruk;
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.4rem 2.4rem;
  cursor: pointer;
  will-change: background-color;
  transition: background-color 0.2s ease;

  @media screen and (max-width: $mb){
    font-size: 2rem;
    padding: 0.4rem 2rem;
  }

  &:hover {
    background-color: rgba(#002169, 0.1);
  }

  &.active {
    background-color: rgba(#002169, 1);
    color: #fff;
  }
}

.checkbox__it {
  &:not(:last-child){
    margin-bottom: 1.4rem;
  }
}

.lk-decor-1 {
  width: 76.7rem;
  left: 50%;
  top: 5.4rem;
  margin-left: -91.6rem;
  position: absolute;

  @media screen and (max-width: $mb) {
    width: 26.3rem;
    margin-left: -23.5rem;
    top: 1rem;
  }
}

.lk-decor-2 {
  width: 32.7rem;
  left: 50%;
  top: 5.4rem;
  margin-left: 57.6rem;
  position: absolute;
}

.lk-decor-3 {
  width: 56.5rem;
  left: 50%;
  bottom: -3rem;
  margin-left: 49.6rem;
  position: absolute;
}

.sc__lk {
  background: url("../img/texture-4.jpg") repeat-y center top;
  background-size: 100%;
  position: relative;

  .container {
    padding-top: 6rem;
    padding-bottom: 16rem;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $mb){
      padding-top: 4.5rem;
      padding-bottom: 8rem;
    }
  }

  .sc__title {
    color: #fff;
  }

  .sc__header {
    &:before {
      display: none;
    }

    margin-bottom: 3rem;
  }
}

.lk {
  &__wrap {
    max-width: 120rem;
    margin-right: auto;
    margin-left: auto;
  }

  &__box {
    @media screen and (min-width: $desk){
      background: url("../img/texture.jpg") repeat-y center top;
      background-size: 100%;
    }

    @media screen and (max-width: $mb) {
      background: url("../img/texture-sm.jpg") repeat-y center top;
      background-size: 100%;
    }

    &:not(:last-child){
      margin-bottom: 3rem;
    }

    &--profile {
      padding: 5.4rem 12.4rem;

      @media screen and (max-width: $mb) {
        padding: 4rem 1.6rem;
      }
    }

    &--prizes {
      padding: 5.4rem 8.8rem 8.8rem;

      @media screen and (max-width: $mb) {
        padding: 4rem 1.6rem;
      }
    }

    &--delivery {
      padding: 5.4rem 8.8rem;

      @media screen and (max-width: $mb) {
        padding: 4rem 1.6rem;
      }
    }

    &-hd {
      margin-bottom: 4rem;

      @media screen and (max-width: $mb) {
        margin-bottom: 2.4rem;
      }
    }

    &-title {
      color: #002169;
      font-family: $fontDruk;
      font-size: 5rem;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;

      @media screen and (max-width: $mb) {
        font-size: 3rem;
        text-align: center;
      }
    }

    &-subtitle {
      color: #000;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;
      margin-top: 2.4rem;
    }

    .form__list {
      .row {
        margin-left: -2.4rem;
        margin-right: -2.4rem;
        margin-bottom: -4.8rem;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: $mb) {
          margin-bottom: -1.6rem;
        }
      }
    }

    .form__it {
      padding-right: 2.4rem;
      padding-left: 2.4rem;
      margin-bottom: 4.8rem;

      @media screen and (max-width: $mb) {
        margin-bottom: 1.6rem;
      }
    }

    @media screen and (min-width: $desk){
      .col-6 {
        width: 50%;
        min-width: 50%;
      }
    }

    .btn {
      padding: 0 3.2rem;

      @media screen and (max-width: $mb) {
        width: 100%;
      }
    }
  }
}

.prizes__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.2rem;

  @media screen and (max-width: $mb) {
    grid-template-columns: repeat(1, 1fr);
    max-width: 23.2rem;
    margin-right: auto;
    margin-left: auto;
    row-gap: 2.4rem;
    display: block;
    flex-wrap: wrap;
  }
}

.icon__gift {
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  background: url("../img/icon-gift.svg") no-repeat center;
  background-size: contain;
  margin-right: 0.8rem;
}

.icon__approve {
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  background: url("../img/approve.svg") no-repeat center;
  background-size: contain;
  margin-right: 0.8rem;
}

.prizes__it {
  position: relative;

  @media screen and (max-width: $mb) {
    &:not(:last-child){
      margin-bottom: 2.4rem;
    }
  }

  @media screen and (min-width: $desk) {
    .card {
      height: 51.5rem;
    }
  }

  .card__img {
    max-width: 27rem;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: $mb){
      max-width: 100%;
    }
  }

  .card__title {
    margin-bottom: 0.8rem;
  }

  &-status {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: #002169;
    font-family: $fontDruk;
    font-size: 3rem;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    position: absolute;
    bottom: 2.4rem;
    left: 0;
    width: 100%;

    @media screen and (max-width: $mb) {
      font-size: 2.4rem;
      position: relative;
      bottom: auto;
    }
  }

  .btn {
    width: 100%;
    padding: 0 2rem;
    text-align: center;
  }

  &-action {
    position: absolute;
    bottom: 2.4rem;
    left: 0;
    padding: 0 2.5rem;
    width: 100%;
  }

  &--disabled {
    .card__subtitle, .card__title, .card__img {
      opacity: 0.5;
    }
  }
}

.btn__ct-hint {
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 130%;
  font-family: $fontBase;
  text-transform: none;
}

img {
  user-select: none;
}

.btn__lk {
  position: relative;

  &-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    padding: 3rem 2.4rem;
    margin-top: 2.5rem;
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;
    transition: opacity 0.2s ease, visibility 0.2s ease;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      height: 2.5rem;
      left: 0;
      right: 0;
    }

    &-it {
      white-space: nowrap;
      color: #00143F;
      font-family: $fontDruk;
      font-size: 3rem;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      will-change: opacity;
      transition: opacity 0.2s ease;
      align-items: center;

      @media screen and (max-width: $mb) {
        color: #fff !important;
        font-size: 2rem;
        padding: 0.4rem 2rem;
      }

      &:hover {
        opacity: 0.75;
      }

      &:last-child {
        opacity: 0.5;

        &:hover {
          opacity: 0.75;
        }
      }

      @media screen and (max-width: $mb) {
        margin-top: 1.6rem;
      }

      &:not(:last-child){
        margin-bottom: 2.4rem;

        @media screen and (max-width: $mb) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.btn__lk-arrow {
  margin-left: 0.7rem;
  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  margin-right: -1rem;

  svg {
    display: block;
    width: 100%;
    overflow: visible;
    height: 100%;
  }
}

.btn__lk {
  .btn {
    padding: 0;
    min-width: 22rem;
  }

  &:hover {
    .btn__lk-dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.icon__lock, .icon__logout {
  width: 2.6rem;
  height: 2.6rem;
  min-width: 2.6rem;
  margin-right: 1.4rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.icon__lock {
  background-image: url("../img/icon-lock.svg");
}

.icon__logout {
  background-image: url("../img/icon-logout.svg");
}

.modal--feedback {
  .form__select-dropdown {
    border-radius: 3rem;
  }
}

.file__clear {
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  margin-left: 0.8rem;

  svg {
    overflow: visible;
    width: 100%;
    display: block;
    height: 100%;
  }
}

.tb--winners {
  min-height: 28rem;
}

.form__attach-btn {
  font-weight: normal;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #002169;
    font-size: 2.4rem;
    font-family: $fontDruk;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20rem;
    overflow: hidden;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 2rem;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

}

.file__attach-btn-icon {
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  margin-right: 1.4rem;

  svg {
    overflow: visible;
    width: 100%;
    display: block;
    height: 100%;
  }
}

.sc__start {
  height: calc(100vh - 9rem);
  position: relative;

  @media screen and (min-width: $desk) {
    background: url("../img/start-bg.jpg") no-repeat center;
    background-size: cover;
  }

  @media screen and (max-width: $mb){
    height: auto;
    min-height: 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
      padding-top: 45rem;
    }
  }

  @media screen and (max-width: $mb){
    &-bg-mb {
      background: url("../img/start-bg-mb.jpg") no-repeat center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 57.3rem;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 20, 63, 0.00) 0%, #00143F 100%);
        height: 19.2rem;
      }
    }
  }
}

.start {
  &__img {
    width: 63.7rem;
    min-width: 63.7rem;
    margin-right: 1.4rem;

    @media screen and (max-width: $mb){
      position: absolute;
      width: 29.8rem;
      min-width: 29.8rem;
      left: 50%;
      margin-left: -16.8rem;
      top: 1.8rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__ct {
    padding-top: 6rem;

    @media screen and (max-width: $mb){
      width: 100%;
      padding-top: 0;
      padding-bottom: 2rem;
    }
  }

  &__subtitle {
    color: #fff;
    font-size: 3rem;
    font-family: $fontCervo;
    font-weight: 500;
    line-height: 3.7rem;
    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: center;
      margin-bottom: 2rem;

      br {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    a {
      color: #fff !important;
    }
  }

  &__legal {
    height: 9rem;
    text-align: center;
    text-transform: uppercase;
    color: #425376;
    padding-top: 0.5rem;
    font-family: $fontCervo;
    font-size: 5rem;
    line-height: 1;
    background: #00143F;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mb){
      height: auto;
      font-size: 2.4rem;
      line-height: 95%;
      padding: 2rem;
    }
  }

  &__title {
    color: #fff;
    font-size: 9rem;
    font-family: $fontCervo;
    font-weight: 500;
    font-style: normal;
    line-height: 95%;
    text-transform: uppercase;
    margin-bottom: 3.1rem;

    @media screen and (max-width: $mb){
      position: absolute;
      top: 13rem;
      left: 0;
      font-size: 4.8rem;
      text-align: center;
      right: 0;
    }
  }

  &__descr {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
    line-height: 140%;
    max-width: 66rem;
    margin-bottom: 8.6rem;

    @media screen and (max-width: $mb){
      font-size: 1.4rem;
      line-height: 130%;
      text-align: center;
      max-width: 33rem;
      margin-left: auto;
      margin-bottom: 3rem;
      margin-right: auto;
    }
  }
}

.sc__lk {
  &:after {
    content: '';
    position: absolute;
    background: url("../img/texture-3.png") repeat-x;
    height: 4.8rem;
    left: 0;
    right: 0;
    bottom: -2.4rem;
    background-size: contain;
    z-index: 3;
  }
}

.sc__login {
  .start__img {
    position: relative;
    margin-right: 61.5rem;
    margin-left: -4rem;

    @media screen and (max-width: $mb){
      position: relative;
      margin-right: 0;
      width: 27.8rem;
      min-width: 27.8rem;
      margin-left: -1.6rem;
      left: 0;
      margin-bottom: 2.9rem;
    }
  }

  @media screen and (min-width: $desk){
    .steps__list {
      position: absolute;
      left: 62.6rem;
      top: 40.4rem;
      width: 44.5rem;
    }
  }

  @media screen and (max-width: $mb){
    .steps__list {
      margin-bottom: 3rem;
    }
  }

  .steps__it-numb, .steps__it-title, .steps__it-descr {
    color: #fff;
  }

  .steps__it-descr {
    font-size: 1.8rem;
    line-height: 130%;

    @media screen and (max-width: $mb){
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: $mb){
    .container {
      padding-top: 0;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.login__header {
  position: absolute;
  top: 15rem;
  left: 20rem;

  @media screen and (max-width: $mb){
    width: 32rem;
    text-align: center;
    left: 1.7rem;
    top: 7rem;
  }
}

.login__title {
  color: #FFF;
  font-family: $fontCervo;
  font-size: 9.631rem;
  font-style: normal;
  font-weight: 500;
  line-height: 95%;
  text-transform: uppercase;
  white-space: nowrap;
  margin-bottom: 0.4rem;

  @media screen and (max-width: $mb){
    font-size: 4.2rem;
    margin-bottom: 0.2rem;
  }
}

.login__ct {
  width: 48rem;
  position: absolute;
  left: 61.1rem;
  top: 16.1rem;

  @media screen and (max-width: $mb){
    width: 30rem;
    left: 2.6rem;
    top: 7.1rem;

    .btn {
      width: 100%;
    }
  }

  &-title {
    margin-bottom: 3.8rem;
    color: #FFF;
    font-family: $fontCervo;
    font-size: 6.4rem;
    font-weight: 500;
    line-height: 95%;
    text-transform: uppercase;

    @media screen and (max-width: $mb){
      font-size: 4.1rem;
      margin-bottom: 18.2rem;
    }
  }

  &-descr {
    color: #FFF;
    font-family: $fontBase;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    margin-bottom: 3.6rem;

    @media screen and (max-width: $mb){
      font-size: 1.5rem;
      margin-bottom: 1.6rem;
    }

    a {
      color: #FFF !important;
      border-bottom: 1px solid rgba(#fff, 0.5);
      will-change: border-color;
      transition: border-color 0.2s ease;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.login__box {
  width: 57rem;
  min-width: 57rem;

  .modal__ct {
    padding: 5rem 6rem !important;

    @media screen and (max-width: $mb){
      padding: 3.2rem 2rem !important;
    }
  }

  @media screen and (max-width: $mb){
    width: 100%;
    min-width: 100%;
  }
}

.login__subtitle {
  color: #FFF;
  font-family: $fontCervo;
  font-size: 6.4207rem;
  font-weight: 500;
  line-height: 95%;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    font-size: 2.3rem;
  }
}

.delivery__box {
  .form__list {
    .row {
      margin-left: -1.9rem;
      margin-right: -1.9rem;
      margin-bottom: -3.2rem;
    }

    .col {
      padding-left: 1.9rem;
      padding-right: 1.9rem;
    }
  }

  .form__it {
    margin-bottom: 3.2rem;
  }
}

.delivery-col {
  width: 40%;
  min-width: 40%;

  &--wide {
    width: 60%;
    min-width: 60%;
  }

  &--full {
    width: 100%;
    min-width: 100%;
  }

  &--small {
    width: 25%;
    min-width: 25%;
  }
}

.delivery__box {
  .form__label {
    span {
      color: #CE0E2D;
    }
  }
}

.login__box {
  position: relative;
  overflow: visible !important;
}

.win__search {
  input {
    user-select: none;
  }
}

.sc__title {
  user-select: none;
}

.login__feedback {
  &-box {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 3.5rem;
    align-items: center;
    right: 0;
    justify-content: space-between;

    @media screen and (max-width: $mb){
      top: auto;
      position: relative;
      margin-top: 4.2rem;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 2rem;

      .btn {
        width: 100%;
      }
    }
  }

  &-descr {
    color: #FFF;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;

    @media screen and (max-width: $mb){
      font-size: 1.6rem;
    }
  }

  &-title {
    color: #FFF;
    font-family: $fontDruk;
    font-size: 5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 95%;
    text-transform: uppercase;
    margin-bottom: 1.2rem;

    @media screen and (max-width: $mb){
      font-size: 3rem;
    }
  }

  @media screen and (max-width: $mb){
    &-ct {
      width: 100%;
      text-align: center;
      margin-bottom: 2.4rem;
    }
  }
}

@media screen and (min-width: $desk){
  .mb-only {
    display: none !important;
  }
}

@media screen and (max-width: $mb){
  .desk-only {
    display: none !important;
  }

  html {
    font-size: 2.7778vw;
  }

  .content--start {
    min-height: calc(100vh - 10.8rem);
  }

  .nav__box {
    position: fixed;
    background: #00143F;
    top: 7.2rem;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 7.2rem);
    margin-right: 0;
    display: none;
    animation-duration: 0.2s !important;

    @supports (height: -webkit-fill-available) {
      height: -webkit-fill-available;
      height: calc(100svh - 10rem);
    }
  }

  .header--open {
    .nav__box {
      display: block;
    }
  }

  .nav__bl {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }

  .icon__bars, .icon__close {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .events__subscribe, .win__search-bl {
    width: 100%;
  }

  .tb--winners {
    .tb__row {
      flex-wrap: wrap;
      padding: 1.6rem;
      position: relative;

      &:not(:last-child){
        margin-bottom: 0.8rem !important;
      }
    }

    .tb__td {
      font-size: 1.4rem;
      line-height: 130%;
    }

    .tb__col {
      width: 100% !important;
      min-width: 100% !important;

      &:not(:last-child){
        margin-bottom: 1.4rem;
      }

      &:nth-child(1), &:nth-child(2){
        padding-left: 8.1rem;
        display: flex;
        align-items: center;

        &:before {
          content: attr(data-title);
          width: 5.4rem;
          min-width: 5.4rem;
          color: #002169;
          font-size: 1.4rem;
          font-weight: 700;
          line-height: 130%;
        }
      }
    }

    .tb__img {
      position: absolute;
      left: 1.6rem;
      top: 1.6rem;
    }

    .tb__body {
      .tb__row {
        border-radius: 1.6rem;
      }
    }
  }

  .sc__stickers-2 {
    height: auto;

    .container {
      flex-wrap: wrap;
    }

    .stickers__ct {
      flex-wrap: wrap;
    }
  }

  .nav__menu {
    margin-bottom: 8rem;
    padding: 0 2rem;
  }

  .nav__bottom {
    width: 100%;
    padding: 0 2rem;
  }

  .form__it {
    &.d-flex {
      flex-wrap: wrap;
    }
  }

  .form__bottom {
    .btn {
      width: 100%;

      &:not(:last-child){
        margin-bottom: 2rem;
      }
    }

    .modal__link {
      text-align: center;
      width: 100%;
    }
  }

  .stickers__title-2 {
    &:last-child {
      margin-bottom: -1rem !important;
    }
  }

  .icon__lock {
    background-image: url("../img/icon-lock--wh.svg");
  }

  .icon__logout {
    background-image: url("../img/icon-logout--wh.svg");
  }

  .nav__bottom-actions {
    display: flex;
    justify-content: center;
  }

  .form__it {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .form__attach-btn {
    margin-bottom: 1.6rem;
  }

  .btn__login {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 10;
    height: 4.4rem;
    width: 8rem;
    padding: 0;
    font-size: 2rem;
  }

  .sc__login {
    .steps__it-title {
      font-size: 2.8rem;
      margin-bottom: 1.2rem;
    }

    .start__img {
      margin-bottom: 17rem;
    }

    .steps__it:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }

  .promo__scroll {
    width: 4.6rem;
    height: 4.6rem;
    min-width: 4.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.2rem solid #fff;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    cursor: pointer;
    margin-left: -2.3rem;

    svg {
      width: 1.4rem;
      height: 0.9rem;
      display: block;
      animation: slideDown 1s ease-in-out infinite;
    }
  }

  .lk__box {
    .prizes__it-status {
      font-size: 2rem;
    }

    .icon__approve {
      width: 1.6rem;
      height: 1.6rem;
      min-width: 1.6rem;
    }
  }

  .prizes__it-action {
    .btn {
      min-width: 0;
    }

    .btn__ct-title {
      font-size: 1.9rem;
    }

    .btn__ct-hint {
      font-size: 1rem;
      font-weight: 700;
      line-height: 110%;
    }
  }

  .prizes__it-action {
    bottom: 0;
    position: relative;
    padding: 0;
    width: 100%;
    margin-top: 1rem;
  }

  .modal--info {
    .modal__title {
      text-align: center;
    }
  }
}

@keyframes slideDown {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 0.5rem);
  }
}
