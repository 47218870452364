$mb: 1023px;
$desk: 1024px;
$color-red: #ED1C24;
$fontBase: 'Stem';
$fontDruk: 'Druk Cyr';
$fontCervo: 'Cervo';
$color: #000000;
$colorBl: #00143F;
$mb: 1023px;
$desk: 1024px;

.modal__close {
  width: 2.8rem;
  height: 2.8rem;
  position: absolute;
  cursor: pointer;
  top: 3.2rem;
  right: 3.2rem;
  opacity: 0.6;
  z-index: 5;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;

  @media screen and (max-width: $mb){
    width: 2.4rem;
    height: 2.4rem;
    right: 2.4rem;
    top: 2.4rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 1;
  }
}

.modal{
  &__backdrop {
    position: fixed;
    background: rgba(#000000, 0.8);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 400;
  }

  &__content {
    position: fixed;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;

    &-box {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
      position: relative;

      @media screen and (max-width: $mb){
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-area {
      min-height: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal__form-text {
  color: #822147;
  font-family: $fontBase;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;

  a {
    color: #AF8B56 !important;
    border-bottom: 1px solid #AF8B56;
    will-change: border-color;
    transition: border-color 0.2s ease;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.btn--full {
  width: 100%;
}

.modal__form {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $mb){
    width: 100%;
  }
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 100;
}

.modal__content {
  overflow-x: hidden;
}

.modal__content-box {
  margin: 0 !important;
  background-color: #FFFFFF;
  width: 100%;
}

.modal__checkbox-it {
  &:not(:last-child){
    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      margin-bottom: 1.4rem;
    }
  }
}

.modal__icon {
  background: $color;
  width: 6rem;
  height: 6rem;
  font-size: 3.2rem;
  border-radius: 50%;
  font-weight: 700;
  color: #fff;
  margin: 0 auto 2.4rem;
}

.modal__subtitle {
  color: #000;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 130%;

  @media screen and (max-width: $mb){
    font-size: 1.4rem;
  }
}

.social__links {
  a{
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;

    @media screen and (max-width: $mb){
      width: 5rem;
      height: 5rem;
    }

    &:not(:last-child){
      margin-right: 1rem;
    }

    circle, path {
      will-change: fill;
      transition: fill 0.2s ease;
    }

    &:hover {
      circle {
        fill: #8E1348;
      }

      path {
        fill: #E9BB6F;
      }
    }

    &:active {
      transform: scale(0.95);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.modal__ct {
  position: relative;
  z-index: 3;
}

.modal__form-submit{
  width: 100%;
}

.modal__content-area {
  display: flex;

  @media screen and (max-width: $mb){
    align-items: flex-start !important;
  }
}

.modal__content-box {
  max-width: 57rem;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-image: url("../img/modal-bg.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $mb){

  }

  &-bl {
    position: relative;
    padding: 5rem 6rem;
    text-align: left;

    @media screen and (max-width: $mb){
      padding: 4rem 3.5rem;
    }
  }

  @media screen and (max-width: $mb){
    margin: 0;
  }
}

.modal__content-area {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: $mb){
    padding-top: 0;
    padding-bottom: 0;
  }
}

.modal__title {
  color: #002169;
  font-family: $fontDruk;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 1.6rem;

  @media screen and (max-width: $mb){
    font-size: 3rem;
  }

  span {
    font-weight: 400;

    @media screen and (max-width: $mb){
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.modal__hint {
  color: #822147;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 130%;
  text-align: left;
}

@media screen and (min-width: $desk){
  .modal__subtitle--indent {
    margin-right: -6rem;
    margin-left: -6rem;
  }
}

.modal__checkboxes {
  .error__message {
    margin-top: 1.2rem;
    margin-left: 2rem;
  }
}

.modal__btns--full {
  .modal__btn {
    &:not(:last-child){
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
}


.modal__btn {
  .btn {
    padding-left: 4rem;
    padding-right: 4rem;

    @media screen and (max-width: $mb){
      width: 100%;
    }
  }

  @media screen and (max-width: $mb){
    width: 100%;
  }

  &:not(:last-child){
    margin-right: 2rem;

    @media screen and (max-width: $mb){
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.modal__prize-img {
  width: 30rem;
  margin: 0 auto 1rem;
  position: relative;

  @media screen and (max-width: $mb){

  }

  img {
    position: relative;
    z-index: 2;
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &:before {
    content: '';
    width: 15.2rem;
    height: 15.2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -7.6rem;
    margin-top: -7.6rem;
    background: rgba(199, 141, 45, 0.40);
    filter: blur(4.43rem);
  }
}

.icon-file {
  width: 3.6rem;
  min-width: 3.6rem;
  height: 3.6rem;
  margin-right: 1.8rem;

  @media screen and (max-width: $mb){
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    overflow: visible !important;
  }
}

.form__hint {
  color: #fff;
  font-size: 1.6rem;
  line-height: 130%;
  text-align: left;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
  }
}
.modal__checkboxes {
  padding-top: 0.8rem;
  padding-bottom: 0.2rem;
}

.modal__bottom-btn {
  margin-bottom: 2.4rem;
}

.modal__link {
  color: #002169;
  font-family: $fontDruk;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    font-size: 2.4rem;
    text-align: center;
  }

  a {
    color: #CE0E2D !important;
    cursor: pointer;
    border-bottom: 1px solid #CE0E2D;
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  &--small {
    font-size: 2.4rem;

    @media screen and (max-width: $mb) {
      font-size: 2rem;
    }

    a {
      border: none !important;
      will-change: color;
      transition: color 0.2s ease;

      &:hover {
        color: rgba(#CE0E2D, 0.75) !important;
      }
    }
  }
}

.modal--info {
  .modal__ct-box {
    &:not(:last-child){
      margin-bottom: 3.2rem;

      @media screen and (max-width: $mb){
        margin-bottom: 2.4rem;
      }
    }
  }
}

.modal__text {
  color: #601F35;
  text-align: center;
  font-family: $fontBase;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 140%;

  @media screen and (max-width: $mb){
    font-size: 1.2rem;
    line-height: 130%;
  }

  b {
    font-weight: 600;
  }

  &:not(:last-child){
    margin-bottom: 4rem;
  }

  a {
    color: #C78D2D !important;
    border-bottom: 1px solid #C78D2D;
    will-change: border-color;
    transition: border-color 0.2s ease;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.modal__ct-box {
  &:not(:last-child){
    margin-bottom: 3.2rem;

    @media screen and (max-width: $mb){
      margin-bottom: 2.4rem;
    }
  }
}

.tg__btn {
  height: 6.4rem;
  border-radius: 3.2rem;
  cursor: pointer;
  background: #FEE9E6;
  padding-left: 1rem;
  padding-right: 3.2rem;
  color: #6A273E;
  text-align: left;
  font-family: $fontBase;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.024rem;
  text-transform: uppercase;
  will-change: color;
  transition: color 0.2s ease;

  &:hover {
    color: #E5357D !important;

    circle {
      fill: #E5357D;
    }
  }

  circle {
    will-change: fill;
    transition: fill 0.2s ease;
  }
}

.tg__btn-icon {
  width: 4.4rem;
  height: 4.4rem;
  min-width: 4.4rem;
  margin-right: 1.4rem;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.form__field {
  position: relative;
}

.icon__success {
  width: 13.6rem;
  height: 13.6rem;
  min-width: 13.6rem;
  background: url("../img/success.png") no-repeat center;
  background-size: contain;
  display: block;
  margin-right: 3.2rem;

  @media screen and (max-width: $mb){
    width: 10rem;
    height: 10rem;
    min-width: 10rem;
    margin-right: 0;
    margin-bottom: 3.2rem;
  }
}

.icon__error {
  width: 6rem;
  height: 6rem;
  margin: 0 auto 2rem;
  //background: url("../img/error.svg") no-repeat center;
  background-size: contain;
  display: block;
}

.modal__form {
  &.form--loading {
    &:after {
      margin-top: -10.8rem;
    }
  }
}

.modal--feedback {
  .modal__hint {
    opacity: 0.5;
  }
}

.modal__descr {
  text-align: center;
  font-size: 1.6rem;
  line-height: 120%;
  margin-bottom: 1rem;
}

.modal__action-close {
  .btn-ct {
    min-width: 37rem;
  }
}

.modal--pass, .modal--info {
  .modal__content-box {
    &:before {
      background-position: center bottom;
      background-size: cover;
      height: 52rem;
    }
  }
}

.modal--feedback {
  .checkbox__title {
    a {
      border: none !important;
      text-decoration: underline !important;
      color: #4C3127 !important;
      will-change: color;
      transition: color 0.2s ease;

      &:hover {
        color: #8E1348 !important;
      }
    }
  }
}

.modal__ct-box--indent {
  padding-top: 3rem;
}

.modal__ct-img {
  max-width: 44rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  &:not(:last-child){
    margin-bottom: 1.6rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.modal--login,
.modal--reg,
.modal--pass {
  .btn {
    padding: 0 3.2rem;
  }
}

.modal__ct--success {
  display: flex;
  align-items: center;

  @media screen and (max-width: $mb){
    flex-wrap: wrap;
    justify-content: center;

    .modal__subtitle {
      text-align: center;
    }
  }
}

.modal--prize {
  .modal__title,
  .modal__subtitle {
    text-align: center;
  }

  .modal__ct-box {
    &:not(:last-child){
      margin-bottom: 2rem;
    }
  }
}

.modal__ct-box {
  .card {
    display: flex;
    background: transparent;
    border-radius: 0;
    padding: 0;

    &__img {
      width: 19rem;
      min-width: 19rem;
      margin-right: 2px;
      border-radius: 3rem 0 0 3rem;
      background: #fff;
      padding: 2rem;
      margin-bottom: 0;

      @media screen and (max-width: $mb){
        width: 11rem;
        min-width: 11rem;
        padding: 1.5rem;
        border-radius: 2rem 0 0 2rem;
      }
    }

    &__ct {
      min-width: 25.8rem;
      width: 25.8rem;
      border-radius: 0 3rem 3rem 0;
      background: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: $mb){
        min-width: 17.7rem;
        width: 17.7rem;
        border-radius: 0 2rem 2rem 0;
      }
    }

    &__subtitle {
      font-size: 2.4rem;
      margin-bottom: 0.8rem;

      @media screen and (max-width: $mb){
        font-size: 2rem;
      }
    }

    &__title {
      font-size: 3rem;

      @media screen and (max-width: $mb){
        font-size: 2.4rem;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $mb){
  .modal__content-box-bl{
    min-height: 100svh;
  }

  .modal--feedback {
    .modal__title {
      span {
        display: inline;
      }
    }
  }

  .form__it--attach span {
    font-size: 1.4rem;
  }

  .modal--prize {
    .modal__text {
      font-size: 1.6rem;

      br {
        display: none;
      }
    }
  }
}